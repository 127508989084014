var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row", staticStyle: { "z-index": "99" } }, [
    _c(
      "div",
      { staticClass: "col-xs-10", staticStyle: { "padding-left": "15px" } },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.value,
              expression: "value",
            },
          ],
          ref: "price",
          staticClass: "number w-100 pr-1 text-right",
          staticStyle: {
            border: "2px #808080 solid",
            "border-radius": "5px",
            width: "100%",
            height: "43px",
            "vertical-align": "middle",
            float: "left",
          },
          attrs: { type: "text" },
          domProps: { value: _vm.value },
          on: {
            change: function ($event) {
              return _vm.PriceChanged($event)
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.value = $event.target.value
            },
          },
        }),
      ]
    ),
    _c(
      "div",
      { staticClass: "col-xs-2" },
      [
        _c("v-select", {
          attrs: {
            outlined: "",
            "menu-props": "auto",
            "hide-details": "",
            items: _vm.currencies,
            "item-text": "text",
            "item-value": "value",
            placeholder: _vm.$t("OP_PleaseSelect"),
            color: "primary",
          },
          on: {
            change: function ($event) {
              return _vm.CurrencyChanged($event, _vm.params)
            },
          },
          model: {
            value: _vm.currency,
            callback: function ($$v) {
              _vm.currency = $$v
            },
            expression: "currency",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }