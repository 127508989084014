var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: _vm.name, staticClass: "autocomplete" },
    [
      _c("v-overlay", {
        staticStyle: { width: "100%" },
        attrs: { value: _vm.isOpen, opacity: "0.05" },
        nativeOn: {
          click: function ($event) {
            return _vm.OnOverlayClick.apply(null, arguments)
          },
        },
      }),
      _c("v-text-field", {
        ref: "input",
        staticClass: "my-1",
        class: { fielderror: _vm.error },
        attrs: {
          clearable: "",
          outlined: "",
          dense: _vm.isInGrid,
          "background-color": _vm.disabled ? "grey lighten-3" : "white",
          "hide-details": "",
          placeholder: _vm.disabled
            ? _vm.$t("IM_SelectionDisabled")
            : _vm.$t("IM_EnterMinCharactersSearch", { min: _vm.minLength }),
          label: _vm.label,
          id: _vm.elementId,
          disabled: _vm.disabled,
          error: _vm.error,
          "prepend-inner-icon": "mdi-database-search",
          "append-icon": _vm.approved == "0" ? "mdi-timer-sand" : "",
        },
        on: {
          input: _vm.onChange,
          click: _vm.OnClick,
          keyup: [
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "down", 40, $event.key, [
                  "Down",
                  "ArrowDown",
                ])
              )
                return null
              return _vm.onArrowDown.apply(null, arguments)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
              )
                return null
              return _vm.onArrowUp.apply(null, arguments)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.onEnter.apply(null, arguments)
            },
          ],
          keydown: _vm.keydown,
          focus: _vm.onFocus,
          "click:clear": function ($event) {
            return _vm.OnClear()
          },
        },
        model: {
          value: _vm.search,
          callback: function ($$v) {
            _vm.search = $$v
          },
          expression: "search",
        },
      }),
      _c(
        "div",
        {
          ref: "list",
          staticClass: "autocomplete-results-div",
          style: `top:${_vm.top}; bottom:${_vm.bottom}; left:${_vm.left}; right:${_vm.right}; min-width:300px; width:auto;`,
        },
        [
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isOpen,
                  expression: "isOpen",
                },
              ],
              staticClass: "autocomplete-results ma-0 pa-0",
              style: `height:${_vm.height}px; min-width:300px; width:auto;`,
              attrs: { id: "autocomplete-results" },
            },
            [
              _vm.isLoading
                ? _c("li", { staticClass: "loading" }, [
                    _vm._v("Yükleniyor..."),
                  ])
                : _vm._e(),
              _vm._l(_vm.results, function (result, i) {
                return _c(
                  "li",
                  {
                    key: i,
                    staticClass: "autocomplete-result",
                    class: { "is-active": i === _vm.arrowCounter },
                    on: {
                      click: function ($event) {
                        return _vm.setResult(result)
                      },
                    },
                  },
                  [_vm._v(_vm._s(result.text))]
                )
              }),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }