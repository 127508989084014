var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "pa-0", attrs: { fluid: "" } },
        [
          _vm.loading
            ? _c(
                "v-card",
                {
                  staticClass: "w-100",
                  attrs: { tile: "", flat: "", outlined: "" },
                },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "font-weight-bold subtitle-2 pa-2 ma-0",
                      staticStyle: { display: "block" },
                    },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "pa-2",
                        attrs: {
                          "max-height": "30",
                          "max-width": "150",
                          type: "image",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-skeleton-loader", {
                    staticClass: "mx-auto",
                    attrs: {
                      type: "table-thead",
                      types: { "table-thead": "heading@4" },
                    },
                  }),
                  _c("v-skeleton-loader", {
                    staticClass: "mx-auto",
                    attrs: {
                      type: "table-tbody",
                      types: {
                        "table-tbody": "table-row-divider@15",
                        "table-row": "table-cell@5",
                      },
                    },
                  }),
                  _c("v-skeleton-loader", {
                    staticClass: "ml-auto",
                    attrs: {
                      "max-width": "500",
                      type: "table-tfoot",
                      types: {
                        "table-tfoot": "text@1, avatar@1, text@2, avatar@4",
                      },
                    },
                  }),
                ],
                1
              )
            : _c("grid", {
                attrs: {
                  columns: _vm.GetViewColumns,
                  data: _vm.GetViewData,
                  header: _vm.ViewTitle,
                  attributes: {},
                  loading: _vm.GetViewLoading,
                  addrow: "",
                  dense: "true",
                  page: _vm.page,
                  itemsperpage: _vm.itemsperpage,
                  clickrow: 1 == 1,
                  showselect: false,
                },
                on: {
                  rowclicked: function ($event) {
                    return _vm.RowClicked($event)
                  },
                  pagechanged: function ($event) {
                    return _vm.PageChanged($event)
                  },
                },
              }),
        ],
        1
      ),
      _c("v-container", { attrs: { fluid: "" } }, [
        _c(
          "form",
          [
            _vm._l(_vm.GetSearchKeys, function (s, index) {
              return _c("field", {
                key: s.fieldname,
                attrs: {
                  column: {
                    type: "text",
                    name: s.fieldname,
                    label: s.label,
                    edit: "0",
                  },
                  state: "0",
                  ":attributes": {},
                  invalue: _vm.GetKeyValue(index),
                },
                on: {
                  change: function ($event) {
                    return _vm.updateViewModel($event, index)
                  },
                  SearchInputChanged: function ($event) {
                    return _vm.SearchInputChanged($event, _vm.col)
                  },
                },
              })
            }),
            _c("v-btn", { attrs: { primary: "" }, on: { click: _vm.Search } }, [
              _vm._v("Ara"),
            ]),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }