var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "mb-60" },
    [
      _vm.FormLoadingStatus
        ? _c(
            "v-col",
            [
              _c(
                "v-alert",
                {
                  staticClass: "mb-3 p-auto",
                  attrs: {
                    dense: "",
                    text: "",
                    tile: "",
                    border: "left",
                    color: "primary",
                  },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "12" } },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "mx-auto",
                        attrs: { "max-height": "20", type: "image" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-container",
                { staticClass: "accent lighten-5" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "mx-auto",
                            attrs: { "max-height": "40", type: "image" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "mx-auto",
                            attrs: { "max-height": "40", type: "image" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "mx-auto",
                            attrs: { "max-height": "40", type: "image" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-skeleton-loader", {
                staticClass: "mx-auto",
                attrs: { type: "divider" },
              }),
              _c(
                "v-expansion-panels",
                {
                  attrs: { accordion: "", multiple: "", tile: "", flat: "" },
                  model: {
                    value: _vm.panel,
                    callback: function ($$v) {
                      _vm.panel = $$v
                    },
                    expression: "panel",
                  },
                },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        {
                          staticClass: "font-weight-bold py-6 accent lighten-5",
                          staticStyle: { "min-height": "0 !important" },
                        },
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "mr-auto",
                            attrs: {
                              "max-height": "20",
                              "max-width": "300",
                              type: "image",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel-content",
                        { staticClass: "py-5" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "6", md: "6" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      "max-height": "40",
                                      type: "image",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6", md: "6" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      "max-height": "40",
                                      type: "image",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "6", md: "6" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      "max-height": "40",
                                      type: "image",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6", md: "6" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      "max-height": "40",
                                      type: "image",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "6", md: "6" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      "max-height": "40",
                                      type: "image",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6", md: "6" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      "max-height": "40",
                                      type: "image",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "4", md: "4" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      "max-height": "40",
                                      type: "image",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "4", md: "4" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      "max-height": "40",
                                      type: "image",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "4", md: "4" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      "max-height": "40",
                                      type: "image",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      "max-height": "65",
                                      type: "image",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      "max-height": "90",
                                      type: "image",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    [
                      _c(
                        "v-expansion-panel-header",
                        {
                          staticClass: "font-weight-bold py-6 accent lighten-5",
                          staticStyle: { "min-height": "0 !important" },
                        },
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "mr-auto",
                            attrs: {
                              "max-height": "30",
                              "max-width": "300",
                              type: "image",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel-content",
                        { staticClass: "py-5" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "6", md: "6" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      "max-height": "40",
                                      type: "image",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6", md: "6" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      "max-height": "40",
                                      type: "image",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "6", md: "6" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      "max-height": "40",
                                      type: "image",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6", md: "6" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      "max-height": "40",
                                      type: "image",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "6", md: "6" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      "max-height": "40",
                                      type: "image",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6", md: "6" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      "max-height": "40",
                                      type: "image",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "4", md: "4" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      "max-height": "40",
                                      type: "image",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "4", md: "4" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      "max-height": "40",
                                      type: "image",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "4", md: "4" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      "max-height": "40",
                                      type: "image",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      "max-height": "65",
                                      type: "image",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      "max-height": "90",
                                      type: "image",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-col",
            [
              _vm.durum != null
                ? _c(
                    "div",
                    [
                      _vm.AnyNotApprovedField
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "mb-3",
                              attrs: {
                                dense: "",
                                dark: "",
                                tile: "",
                                border: "left",
                                icon: "mdi-timer-sand",
                                color: "warning",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("FL_Status")) +
                                  ": " +
                                  _vm._s(
                                    _vm.$t("IM_HaveUnconfirmedInformation")
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _c(
                            "v-alert",
                            {
                              staticClass: "mb-3",
                              attrs: {
                                dense: "",
                                dark: "",
                                tile: "",
                                border: "left",
                                icon: "mdi-information",
                                color: "warning",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("FL_Status")) +
                                  ": " +
                                  _vm._s(_vm.durum) +
                                  " "
                              ),
                            ]
                          ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-tabs",
                {
                  attrs: { grow: "", flat: "", color: "secondary" },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _vm._l(_vm.GetDynoVisibleTabs, function (t) {
                    return _c(
                      "v-tab",
                      {
                        key: t.order,
                        staticClass: "accent lighten-5",
                        class: `taborder-${t.order}` + " font-weight-bolder ",
                        attrs: { href: `#Tab-${t.order}` },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t(t.header)) + " ")]
                    )
                  }),
                  _vm._l(_vm.GetDynoVisibleTabs, function (t) {
                    return _c(
                      "v-tab-item",
                      {
                        key: t.order,
                        staticClass: "grey lighten-4",
                        attrs: { value: "Tab-" + t.order },
                      },
                      [
                        _c(
                          "v-expansion-panels",
                          {
                            attrs: { accordion: "", multiple: "", tile: "" },
                            model: {
                              value: _vm.panel,
                              callback: function ($$v) {
                                _vm.panel = $$v
                              },
                              expression: "panel",
                            },
                          },
                          _vm._l(
                            _vm.GetDynoTabGroups(t.order),
                            function (g, index) {
                              return _c(
                                "v-expansion-panel",
                                { key: g.order, attrs: { value: 0 } },
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    {
                                      staticClass:
                                        "font-weight-bold py-6 accent lighten-5",
                                      staticStyle: {
                                        "min-height": "0 !important",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t(g.header)) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-expansion-panel-content",
                                    { staticClass: "py-5" },
                                    [
                                      _vm.GetShowHeader(g.order)
                                        ? _c(
                                            "v-row",
                                            {
                                              attrs: {
                                                id: "gheader_" + g.order,
                                              },
                                            },
                                            [
                                              _vm._l(
                                                _vm.GetVisibleGroupColumns(
                                                  g.order
                                                ),
                                                function (col) {
                                                  return _c(
                                                    "v-col",
                                                    {
                                                      key: col.name,
                                                      class:
                                                        _vm.GetColumnAttributes(
                                                          col.name
                                                        ).hidden
                                                          ? "mx-0 pa-0"
                                                          : "mx-0",
                                                      attrs: {
                                                        cols: "12",
                                                        md:
                                                          col.col != ""
                                                            ? col.col
                                                            : "6",
                                                      },
                                                    },
                                                    [
                                                      _c("field", {
                                                        attrs: {
                                                          state:
                                                            _vm.GetFormState,
                                                          column: col,
                                                          attributes:
                                                            _vm.GetColumnAttributes(
                                                              col.name
                                                            ),
                                                          invalue: _vm.GetField(
                                                            col.name,
                                                            col
                                                          ),
                                                          doviz:
                                                            _vm.GetField(
                                                              col.doviz,
                                                              col
                                                            ) || "",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.updateFormModel(
                                                              $event,
                                                              col
                                                            )
                                                          },
                                                          changeGrid: function (
                                                            $event
                                                          ) {
                                                            return _vm.UpdateExtDataModel(
                                                              $event,
                                                              col
                                                            )
                                                          },
                                                          SearchInputChanged:
                                                            function ($event) {
                                                              return _vm.SearchInputChanged(
                                                                $event,
                                                                col
                                                              )
                                                            },
                                                          linkclicked:
                                                            function ($event) {
                                                              return _vm.LinkClicked(
                                                                col
                                                              )
                                                            },
                                                          buttonclick:
                                                            function ($event) {
                                                              return _vm.ButtonClicked(
                                                                $event
                                                              )
                                                            },
                                                          FieldButtonClicked:
                                                            function ($event) {
                                                              return _vm.FieldButtonClicked(
                                                                $event
                                                              )
                                                            },
                                                          changeAttribute:
                                                            function ($event) {
                                                              return _vm.ChangeAttribute(
                                                                $event,
                                                                col
                                                              )
                                                            },
                                                          RowButtonClicked:
                                                            function ($event) {
                                                              return _vm.RowButtonClicked(
                                                                $event,
                                                                col
                                                              )
                                                            },
                                                          HeaderButtonClicked:
                                                            function ($event) {
                                                              return _vm.HeaderButtonClicked(
                                                                $event
                                                              )
                                                            },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              _vm.GetDynoTabGroups(t.order)
                                                .length -
                                                1 ==
                                              index
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "text-start",
                                                      attrs: { cols: "2" },
                                                    },
                                                    [
                                                      t.previousButtonId !=
                                                      undefined
                                                        ? _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    depressed:
                                                                      "",
                                                                    block: "",
                                                                    color:
                                                                      "third",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.ChangeActiveTab(
                                                                          t.previousButtonId
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "FL_PreviousPage"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c("v-col", {
                                                attrs: { cols: "8" },
                                              }),
                                              _vm.GetDynoTabGroups(t.order)
                                                .length -
                                                1 ==
                                              index
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "text-end",
                                                      attrs: { cols: "2" },
                                                    },
                                                    [
                                                      t.nextButtonId !=
                                                      undefined
                                                        ? _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    depressed:
                                                                      "",
                                                                    block: "",
                                                                    color:
                                                                      "third",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.ChangeActiveTab(
                                                                          t.nextButtonId
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "FL_NextPage"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }