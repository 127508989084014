var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "800", scrollable: "" },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-5" },
        [
          _c(
            "v-card-title",
            [
              _c("span", { staticClass: "text-h5" }, [
                _vm._v(_vm._s(_vm.$t(_vm.title))),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.CloseDialog()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c("br"),
          _c("v-card-text", {
            staticStyle: { height: "600px" },
            domProps: { innerHTML: _vm._s(_vm.$t(_vm.content)) },
          }),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass: "text-capitalize px-5",
                  attrs: { text: "", block: "", tile: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.DownloadDocument()
                    },
                  },
                },
                [
                  _c("span", { staticClass: "caption" }, [
                    _vm._v(_vm._s(_vm.$t("AL_Download"))),
                  ]),
                  _c("v-icon", { attrs: { small: "", right: "" } }, [
                    _vm._v("mdi-download"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }