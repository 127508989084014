<template>
    <div>
        <!-- slot for parent component to activate the file changer -->
        <div @click="launchFilePicker()">
            <v-avatar tile size="150px" v-ripple v-if="!imageURL" class="grey lighten-3 mb-3">
                <span v-if="editable">{{ $t("AL_Upload") }}</span>
            </v-avatar>
            <v-avatar tile size="150px" v-ripple v-else class="mb-3">
                <img :src="imageURL" alt="file">
            </v-avatar>
        </div>
        <!-- image input: style is set to hidden and assigned a ref so that it can be triggered -->
        <input type="file"
               ref="file"
               :name="uploadFieldName"
               @change="onFileChange(
          $event.target.name, $event.target.files)"
               style="display:none">
        <!-- error dialog displays any potential error messages -->
        <v-dialog v-model="errorDialog" max-width="300">
            <v-card>
                <v-card-text class="subheading">{{errorText}}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="errorDialog = false" flat>{{ $t("AL_Okey") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import axios from "axios";
    import Utilities from '@/Utilities.js'
    export default {
        name: 'image-upload',
        data: () => ({
            file: "",
            filenamefield: "",
            uploaded: false,
            imageURL: null,
            errorDialog: null,
            errorText: '',
            uploadFieldName: 'file',
            maxSize: 1024
        }),
        props: {
            params: Object,
            invalue: String,
            editable: Boolean
        },
        mounted()
        {
            Utilities.log("---upload Mounted invalue" + JSON.stringify(this.invalue));
            this.filenamefield = this.invalue || "";
            this.uploaded = this.filenamefield != "";
            if (this.invalue)
            {
                var url = "/kforms/showfile.aspx?file=" + this.invalue;
                Utilities.log("mounturl:" + url);
                this.imageURL = url;
            }
        },

        watch: {
            invalue: function (val, old)
            {
                // Utilities.log(
                //     "File Watch:" + this.params.name + " Val:" + val + " Old:" + old
                // );
                this.value = val;
                this.filenamefield = val;
                if (val && val.indexOf('upload/files') >= 0)
                {
                    var url = "/kforms/showfile.aspx?file=" + val;
                    Utilities.log("watchval:" + val);
                    this.imageURL = url;
                }
                else if (old && !val)
                    this.imageURL = null;
            }
        },
        methods: {
            launchFilePicker()
            {
                if (this.editable)
                {
                    Utilities.log('launchFilePickerrr');
                    this.$refs.file.click();
                }
            },
            onFileChange(fieldName, file)
            {
                Utilities.log('onFileChange');
                Utilities.log('fieldName:' + fieldName);
                Utilities.log('file:' + file);
                const { maxSize } = this
                let imageFile = file[0]
                if (file.length > 0)
                {
                    let size = imageFile.size / maxSize / maxSize
                    if (!imageFile.type.match('image.*'))
                    {
                        // check whether the upload is an image
                        this.errorDialog = true
                        this.errorText = this.$root.$i18n.t("EM_SelectImageFile")
                    } else if (size > 1)
                    {
                        // check whether the size is greater than the size limit
						this.errorDialog = true;
                        this.errorText = this.$root.$i18n.t("EM_FileIsTooLarge")
                    } else
                    {
                        //// Append file into FormData and turn file into image URL
                        //let formData = new FormData()
                        //let imageURL = URL.createObjectURL(imageFile)
                        //formData.append(fieldName, imageFile)
                        //// Emit the FormData and image URL to the parent component
                        ////this.$emit('input', { formData, imageURL })

                        //this.file.imageURL = imageURL;

                        let imageURL = URL.createObjectURL(imageFile);
                        this.imageURL = imageURL;

                        let formData = new FormData();
                        formData.append("file", imageFile);
                        var self = this;
                        axios
                            .post(
                                 "/portal/api/upload",
                                formData, 
                                {
                                    headers: {
                                        "Content-Type": "multipart/form-data"
                                    }
                                }
                            )
                            .then(function (response)
                            {
                                try
                                {
                                    Utilities.log("SUCCESS!!:" + JSON.stringify(response));
                                    let file = response.data.files[0].url;
                                    Utilities.log("File:" + file);
                                    var error = response.data.files[0].error || "";
                                    //if (error != "")
                                    //{
                                    //    alert(self.file.name + " Güvenlik kuralları gereği yüklenemez");
                                    //    return;
                                    //}
                                    var payload = { fieldName: self.params.name, fieldValue: file };
                                    self.$emit("change", payload);
                                    self.file = file;
                                    self.uploaded = true;

                                } catch (e)
                                {
                                    Utilities.log("file upload exception:" + e);
                                }
                            })
                            .catch(function ()
                            {
                                Utilities.log("FAILURE!!");
                            });
                    }
                }
            }
        }
    }
</script>
