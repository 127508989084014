var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dropdown w-100",
      class: { expanded: _vm.isExpanded },
      on: { click: _vm.toggleList },
    },
    [
      _c("div", { staticClass: "dropdown-label-container w-100" }, [
        _c(
          "div",
          {
            staticClass: "dropdown-label",
            staticStyle: {
              border: "2px #808080 solid",
              "border-radius": "5px",
              height: "43px",
              width: "80%",
              "vertical-align": "middle",
              float: "left",
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "text",
                staticStyle: {
                  "padding-right": "20px",
                  "padding-left": "10px",
                },
              },
              [_vm._v(" " + _vm._s(_vm.placeholder) + " ")]
            ),
            _c("em", {
              staticClass: "angle-down",
              class: { toggled: _vm.isExpanded },
            }),
          ]
        ),
      ]),
      _vm.isExpanded
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "expand",
                  rawName: "v-expand",
                  value: _vm.isExpanded,
                  expression: "isExpanded",
                },
              ],
              staticClass: "options expand",
              staticStyle: {
                border: "2px #808080 solid",
                "border-radius": "5px",
                height: "100%",
                width: "80%",
                "vertical-align": "middle",
                "background-color": "white",
                "z-index": "999",
                float: "left",
              },
            },
            _vm._l(_vm.configOptions, function (option) {
              return _c(
                "div",
                {
                  staticClass: "option",
                  on: {
                    click: function ($event) {
                      return _vm.setCurrentSelectedOption(option)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(option.text) + " ")]
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }