<template>
	<v-app>
		<router-view :single="single" />
	</v-app>
</template>
<script>
	import Vue from 'vue';
	import Vuex from 'vuex';

	import { mapGetters } from 'vuex';
	import dynoform from '@/components/DynoForm.vue';
	import grid from '@/components/grid.vue';
	import leftmenu from '@/components/leftmenu.vue';
	import kview from '@/components/view.vue';
	import axios from 'axios';
	import _ from 'lodash';
	import 'material-design-icons-iconfont/dist/material-design-icons.css';
	import { FormMixIn } from '@/MixIns/FormMixins';
	import store from '@/store';
	import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

	import 'bootstrap/dist/css/bootstrap.css'
	import 'bootstrap-vue/dist/bootstrap-vue.css'

	import moment from 'moment';
	import Utilities from '@/Utilities.js'

	import showactionresult from '@/components/ShowActionResult.vue';
	Vue.component('showactionresult', showactionresult);

    import showdialog from '@/components/ShowDialog.vue';
	Vue.component('showdialog', showdialog);

    import terms from '@/components/AgreementDialog.vue';
    Vue.component('terms', terms);

	export default {
		name: 'portal',
		mixins: [FormMixIn],
		components: {
			dynoform,
			grid,
			leftmenu,
			kview
		},
		props: {
			//form: String,
			formid: Number,
			viewname: String,
			message: Object
		},
		data: function ()
		{
			return {
				drawer: null,
				valid: false,
				messages: { messageType: '', messageBody: '', infoBody: '', viewType: '' },
				lazy: true,
				username: '',
				viewName: '',
				form: '',
				validateError: '',
				single: false,
			};
		},

		mounted: function ()
		{
			const self = this;
            var query = self.$route.query;

			if (_.isEmpty(query))
			{
				Utilities.log("MountedCheck NoQuerry ", query);
                self.single = false;
			}
			else
			{
				Utilities.log("MountedCheck single page APP ", query);
				if (typeof query.show != "undefined")
				{

					var showSingle = query.show == 'single';
					Utilities.log("Show single page APP ", query, showSingle);
                    self.single = showSingle;

				}
				else
				{

					Utilities.log("Show full page APP", query);
                    self.single = false;
				}
			}
		},
		watch:
		{
		}
	};
</script>
