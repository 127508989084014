var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sa" }, [
    _vm.isIcon("error")
      ? _c("div", { staticClass: "sa-error" }, [
          _vm._m(0),
          _c("div", { staticClass: "sa-error-placeholder" }),
          _c("div", { staticClass: "sa-error-fix" }),
        ])
      : _vm.isIcon("warning")
      ? _c("div", { staticClass: "sa-warning" }, [
          _c("div", { staticClass: "sa-warning-body" }),
          _c("div", { staticClass: "sa-warning-dot" }),
        ])
      : _vm.isIcon("info")
      ? _c("div", { staticClass: "sa-info" }, [
          _c("div", { staticClass: "sa-info-body" }),
          _c("div", { staticClass: "sa-info-dot" }),
        ])
      : _vm.isIcon("loading")
      ? _c("div", { staticClass: "sa-loading" }, [
          _c("div", { staticClass: "sa-loading-body" }),
        ])
      : _c("div", { staticClass: "sa-success" }, [
          _c("div", { staticClass: "sa-success-tip" }),
          _c("div", { staticClass: "sa-success-long" }),
          _c("div", { staticClass: "sa-success-placeholder" }),
          _c("div", { staticClass: "sa-success-fix" }),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sa-error-x" }, [
      _c("div", { staticClass: "sa-error-left" }),
      _c("div", { staticClass: "sa-error-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }