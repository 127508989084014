var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "w-100",
      class: {
        gridError: _vm.Error,
        fieldDisabled: _vm.Disabled,
        fieldHidden: _vm.Hidden,
      },
      attrs: { flat: "", outlined: "", error: _vm.Error },
    },
    [
      _c(
        "v-card-title",
        { staticClass: "font-weight-bold subtitle-2 pa-2 ma-0" },
        [
          _c("div", { staticClass: "mr-12" }, [
            _vm._v(" " + _vm._s(_vm.header) + " "),
          ]),
          _c("v-spacer"),
          _vm.showExportToExcelButton
            ? _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.data.length,
                      expression: "data.length",
                    },
                  ],
                  staticStyle: { "background-color": "#E8E8E8" },
                  attrs: {
                    outlined: "",
                    rounded: "",
                    text: "",
                    depressed: "",
                    color: "secondary",
                  },
                  on: { click: _vm.GridToExcel },
                },
                [
                  _c("v-icon", { attrs: { left: "", small: "" } }, [
                    _vm._v("mdi-file-excel"),
                  ]),
                  _vm._v(" " + _vm._s(_vm.$t("AL_ExportToExcel")) + " "),
                ],
                1
              )
            : _vm._e(),
          _vm.delrowlabel != ""
            ? _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  staticStyle: { "background-color": "#fef1e9" },
                  attrs: { outlined: "", rounded: "", text: "", depressed: "" },
                  on: {
                    click: function ($event) {
                      return _vm.DelRow()
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { left: "", small: "" } }, [
                    _vm._v("mdi-trash-can-outline"),
                  ]),
                  _vm._v(" " + _vm._s(_vm.delrowlabel) + " "),
                ],
                1
              )
            : _vm._e(),
          _vm.addrow != ""
            ? _c(
                "v-btn",
                {
                  staticClass: "ml-1",
                  attrs: {
                    outlined: "",
                    rounded: "",
                    text: "",
                    depressed: "",
                    color: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.AddRow()
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { left: "", small: "" } }, [
                    _vm._v("mdi-plus-circle"),
                  ]),
                  _vm._v(" " + _vm._s(_vm.$t(_vm.addrow)) + " "),
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.buttons, function (b) {
            return _c(
              "v-btn",
              {
                key: b.text,
                staticClass: "ml-1",
                attrs: { outlined: "", rounded: "", text: "", color: b.color },
                on: {
                  click: function ($event) {
                    return _vm.HeaderButtonClicked(
                      _vm.tableName + ":::" + b.text
                    )
                  },
                },
              },
              [
                _c("v-icon", { attrs: { left: "", small: "" } }, [
                  _vm._v("mdi-" + _vm._s(b.icon)),
                ]),
                _vm._v(" " + _vm._s(b.text) + " "),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm.addrow != ""
        ? _c("v-card-subtitle", { staticClass: "ma-0 pb-1 pl-2" }, [
            _c("p", { staticClass: "ma-0 pa-0" }, [
              _vm._v(_vm._s(_vm.$t("IM_AddRowToTable"))),
            ]),
          ])
        : _vm._e(),
      _c("v-data-table", {
        staticClass: "spaced",
        attrs: {
          "footer-props": _vm.footerProps,
          headers: _vm.GetHeaders,
          items: _vm.data,
          search: _vm.searchkey,
          options: _vm.options,
          loading: _vm.loading,
          "loading-text": "$t('IM_LoadingText')",
          "show-select": _vm.showselect,
          "item-key": "id",
          "group-by": _vm.GetGroupBy,
          "show-group-by": _vm.GetGroupBy.length > 0,
          expanded: [],
          "show-expand": false,
          "no-data-text": _vm.$t("IM_NoDataDisplay"),
        },
        on: {
          "update:options": function ($event) {
            _vm.options = $event
          },
          "item-expanded": function ($event) {
            return _vm.Dump("Item-Expanded event", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function () {
              return [
                _vm.searchable
                  ? _c("v-text-field", {
                      staticClass: "ma-0 pa-2 rounded-0",
                      attrs: {
                        placeholder: "$t('AL_Search')",
                        tile: "",
                        dense: "",
                        "hide-details": "",
                        clearable: "",
                        loading: "0",
                        "prepend-inner-icon": "mdi-magnify",
                        color: "accent darken-2",
                      },
                      model: {
                        value: _vm.searchkey,
                        callback: function ($$v) {
                          _vm.searchkey = $$v
                        },
                        expression: "searchkey",
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "item",
            fn: function (props) {
              return [
                _c(
                  "tr",
                  {
                    staticClass: "w-100",
                    class: { rowactive: props.isSelected },
                    attrs: { id: "Row_" + props.index },
                  },
                  [
                    _vm.showselect
                      ? _c(
                          "td",
                          [
                            _vm._v(" " + _vm._s(_vm.Dump("Prop", props)) + " "),
                            _c("v-checkbox", {
                              ref: props.index,
                              staticClass: "ma-0 pa-0",
                              attrs: { color: "", "hide-details": "" },
                              on: {
                                click: function ($event) {
                                  return _vm.SelectRow($event, props.item)
                                },
                              },
                              model: {
                                value: props.isSelected,
                                callback: function ($$v) {
                                  _vm.$set(props, "isSelected", $$v)
                                },
                                expression: "props.isSelected",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._l(_vm.columns, function (col, i) {
                      return col.type != "hidden"
                        ? _c(
                            "td",
                            {
                              key: i,
                              style: _vm.GetCustomStyle(i),
                              attrs: {
                                id:
                                  _vm.tableName +
                                  "_Row_" +
                                  props.index +
                                  "_" +
                                  i,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.RowClicked(props)
                                },
                                dblclick: function ($event) {
                                  return _vm.RowDooubleClicked(props)
                                },
                              },
                            },
                            [
                              _c("gridfield", {
                                attrs: {
                                  state: _vm.GetState,
                                  column: _vm.getColDefinition(i),
                                  label: _vm.getColLabel(i),
                                  placeholder: _vm.getColLabel(i),
                                  invalue:
                                    _vm.getColDefinition(i).type == "button"
                                      ? _vm.getColDefinition(i).label
                                      : props.item[i],
                                  row: props.index,
                                  col: i,
                                  type: _vm.getColType(i),
                                  attributes: _vm.GetColAttributes(
                                    i,
                                    props.item.rowid
                                  ),
                                  tableName: _vm.tableName,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.updateExtdataModel(
                                      $event,
                                      i,
                                      props.item
                                    )
                                  },
                                  click: function ($event) {
                                    return _vm.ButtonClicked($event, props.item)
                                  },
                                  SearchInputChanged: function ($event) {
                                    return _vm.SearchInputChanged(
                                      $event,
                                      col,
                                      props.item
                                    )
                                  },
                                  changeAttribute: function ($event) {
                                    return _vm.ChangeAttribute(
                                      $event,
                                      props.item
                                    )
                                  },
                                  rowupdown: function ($event) {
                                    return _vm.RowUpDown($event, i, props)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e()
                    }),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "item.action",
            fn: function ({ item }) {
              return [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-2",
                    attrs: { small: "" },
                    on: {
                      click: function ($event) {
                        return _vm.ActionClicked(item, "Edit")
                      },
                    },
                  },
                  [_vm._v(" zoom_out_map ")]
                ),
              ]
            },
          },
          {
            key: "default",
            fn: function (loading) {
              return [_vm._v(" " + _vm._s(_vm.$t("IM_Loading")) + " ")]
            },
          },
        ]),
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }