var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          on: {
            click: function ($event) {
              return _vm.launchFilePicker()
            },
          },
        },
        [
          !_vm.imageURL
            ? _c(
                "v-avatar",
                {
                  directives: [{ name: "ripple", rawName: "v-ripple" }],
                  staticClass: "grey lighten-3 mb-3",
                  attrs: { tile: "", size: "150px" },
                },
                [
                  _vm.editable
                    ? _c("span", [_vm._v(_vm._s(_vm.$t("AL_Upload")))])
                    : _vm._e(),
                ]
              )
            : _c(
                "v-avatar",
                {
                  directives: [{ name: "ripple", rawName: "v-ripple" }],
                  staticClass: "mb-3",
                  attrs: { tile: "", size: "150px" },
                },
                [_c("img", { attrs: { src: _vm.imageURL, alt: "file" } })]
              ),
        ],
        1
      ),
      _c("input", {
        ref: "file",
        staticStyle: { display: "none" },
        attrs: { type: "file", name: _vm.uploadFieldName },
        on: {
          change: function ($event) {
            return _vm.onFileChange($event.target.name, $event.target.files)
          },
        },
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "300" },
          model: {
            value: _vm.errorDialog,
            callback: function ($$v) {
              _vm.errorDialog = $$v
            },
            expression: "errorDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", { staticClass: "subheading" }, [
                _vm._v(_vm._s(_vm.errorText)),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "" },
                      on: {
                        click: function ($event) {
                          _vm.errorDialog = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("AL_Okey")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }