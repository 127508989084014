var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-100 h-100" }, [
    _c(
      "div",
      { staticClass: "h-100 w-100" },
      [
        _c("input", {
          ref: "fileu",
          staticStyle: { position: "absolute", top: "-90000px" },
          attrs: { type: "file" },
          on: {
            change: function ($event) {
              return _vm.handleFileUpload()
            },
          },
        }),
        _vm.editable
          ? _c("v-text-field", {
              ref: "filename",
              staticClass: "mb-2",
              class: { fielderror: _vm.error },
              attrs: {
                clearable: "",
                placeholder: _vm.$t(_vm.params.label),
                label: _vm.isInGrid ? "" : _vm.label,
                "background-color":
                  _vm.editable && !_vm.disabled ? "white" : "grey lighten-3",
                disabled: _vm.disabled,
                error: _vm.error,
                outlined: "",
                readonly: "",
                "hide-details": "",
                dense: _vm.isInGrid,
                "append-icon": _vm.approved == false ? "mdi-timer-sand" : "",
                value: _vm._f("FormatFileName")(
                  _vm.filenamefield,
                  _vm.$t(_vm.params.label)
                ),
              },
              on: {
                "click:clear": function ($event) {
                  return _vm.clearFile()
                },
              },
            })
          : _vm._e(),
        _vm.editable && !_vm.disabled
          ? _c(
              "v-btn",
              {
                staticClass: "mr-2 white--text",
                attrs: {
                  depressed: "",
                  tile: "",
                  small: "",
                  color: "secondary",
                },
                on: {
                  click: function ($event) {
                    return _vm.fileselect()
                  },
                },
              },
              [
                _c("v-icon", { attrs: { left: "", small: "" } }, [
                  _vm._v("attachment"),
                ]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.params.newFileLabel != undefined
                        ? _vm.$t(_vm.params.newFileLabel)
                        : _vm.filenamefield == ""
                        ? ""
                        : _vm.$t("AL_New") + " "
                    ) +
                    " " +
                    _vm._s(
                      _vm.params.newFileLabel != undefined
                        ? ""
                        : _vm.$t("AL_SelectFile")
                    ) +
                    " "
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.uploaded
          ? _c(
              "v-btn",
              {
                staticClass: "mr-2 white--text",
                attrs: {
                  depressed: "",
                  tile: "",
                  small: "",
                  color: "secondary",
                },
                on: {
                  click: function ($event) {
                    return _vm.showFile()
                  },
                },
              },
              [
                _c("v-icon", { attrs: { left: "", small: "" } }, [
                  _vm._v("visibility"),
                ]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.params.showFileLabel != undefined
                        ? _vm.$t(_vm.params.showFileLabel)
                        : _vm.$t("AL_View")
                    ) +
                    " "
                ),
              ],
              1
            )
          : !_vm.editable
          ? _c(
              "div",
              {
                staticClass: "body-2 font-weight-thin unspecified font-italic",
              },
              [_vm._v(" " + _vm._s(_vm.$t("ST_Undefined")) + " ")]
            )
          : _vm._e(),
        _vm.params.draft
          ? _c(
              "v-btn",
              {
                staticClass: "white--text",
                attrs: {
                  depressed: "",
                  tile: "",
                  small: "",
                  color: "secondary",
                },
                on: {
                  click: function ($event) {
                    return _vm.showDraft()
                  },
                },
              },
              [
                _c("v-icon", { attrs: { left: "", small: "" } }, [
                  _vm._v("mdi-file-document"),
                ]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.params.draftLabel != undefined
                        ? _vm.$t(_vm.params.draftLabel)
                        : _vm.$t("AL_ShowDraft")
                    ) +
                    " "
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }