import Vue from 'vue'
import Router from 'vue-router'
import Utilities from '@/Utilities.js'
import store from './store'
import { loadLanguageAsync } from '@/plugins/i18n'

Vue.use(Router)

const _routes = [
    {
        path: '/Account',
        component: () => import('@/layouts/LoginLayout.vue'),
        props: {
            allowUnAuthorized: true
        },
        children: [
            {
                path: 'Login',
                name: 'account.login',
                component: () => import('@/views/Account/Login.vue')
            },
            {
                path: 'Register',
                name: 'account.register',
                component: () => import('@/views/Account/Register.vue'),
            },
            {
                path: 'Forgot',
                name: 'account.forgot',
                component: () => import('@/views/Account/Forgot.vue')
            },
            {
                path: 'Application',
                name: 'account.application',
                component: () => import('@/views/Account/Application.vue')
            },
        ]
    },
    {
        path: '/',
        redirect: '/Anasayfa'
    },
    {
        path: '/Anasayfa',
        props: {
            form: 'home'
        },
        component: () => import('./layouts/pagelayout.vue'),
        children: [
            {
                path: '',
                name: 'home',
                component: () => import('./views/Home.vue'),
            }]
    },

    {
        path: '/Sertifikalar',
        props: {
            form: 'sertifika'
        },
        component: () => import('./layouts/formlayout.vue'),
        children: [
            {
                path: '',
                name: 'sertifikaview',
                component: () => import('./views/sertifika.vue'),
            }]
    },
    {
        path: '/Degerlendirmelerim',
        props: {
            form: 'tedarikcidegerlendirme'
        },
        component: () => import('./layouts/formlayout.vue'),
        children: [
            {
                path: '',
                name: 'tedarikcidegerlendirmeview',
                component: () => import('./views/Forms/degerlendirme.vue'),
            },
            {
                path: ':id',
                name: 'tedarikcidegerlendirmeform',
                component: () => import('./views/Forms/degerlendirme.vue'),
            }
        ]
    },
    {
        path: '/Kullanicilarim',
        props: {
            form: 'users'
        },
        component: () => import('./layouts/formlayout.vue'),
        children: [
            {
                path: '',
                name: 'users',
                component: () => import('./views/Users.vue'),
            }]
    },
    {
        path: '/changePassword',
        props: {
            form: 'changePassword'
        },
        component: () => import('./layouts/formlayout.vue'),
        children: [
            {
                path: '',
                name: 'changePassword',
                component: () => import('./views/ChangePassword.vue'),
            }]
    },
    {
        path: '/Bilgilerim',
        props: {
            form: 'tedarik'
        },
        component: () => import('./layouts/pagelayout.vue'),
        children: [
            {
                path: '',
                name: 'tedarik',
                component: () => import('./views/bilgilerim.vue'),
            }]
    },
    {
        path: '/Hakedis',
        props: {
            form: 'hakedis'
        },
        component: () => import('./layouts/formlayout.vue'),
        children: [
            {
                path: '',
                name: 'hakedisview',
                component: () => import('./views/Forms/hakedis.vue'),
            },
            {
                path: ':id',
                name: 'hakedisform',
                component: () => import('./views/Forms/hakedis.vue'),
            }
        ]
    },
    {
        path: '/SiparisKalemler',
        props: {
            form: 'sipariskalemler'
        },
        component: () => import('./layouts/pagelayout.vue'),
        children: [
            {
                path: '',
                name: 'sipariskalemler',
                component: () => import('./views/Pages/sipariskalemler.vue'),
            }]
    },
    {
        path: '/IrsaliyeKalemler',
        props: {
            form: 'faturaolustur'
        },
        component: () => import('./layouts/pagelayout.vue'),
        children: [
            {
                path: '',
                name: 'irsaliyekalemler',
                component: () => import('./views/Pages/faturaolustur.vue'),
            }]
    },
    {
        path: '/Irsaliyeler',
        props: {
            form: 'irsaliye'
        },
        component: () => import('./layouts/formlayout.vue'),
        children: [
            {
                path: '',
                name: 'irsaliyeview',
                component: () => import('./views/Forms/irsaliye.vue'),
            },
            {
                path: ':id',
                name: 'irsaliyeform',
                component: () => import('./views/Forms/irsaliye.vue'),
            }
        ]
    },
    {
        path: '/Sozlesmeler',
        props: {
            form: 'sozlesme'
        },
        component: () => import('./layouts/formlayout.vue'),
        children: [
            {
                path: '',
                name: 'sozlesmeview',
                component: () => import('./views/Forms/sozlesme.vue'),
            },
            {
                path: ':id',
                name: 'sozlesmeform',
                component: () => import('./views/Forms/sozlesme.vue'),
            }]
    },
    {
        path: '/Siparisler',
        props: {
            form: 'siparis'
        },
        component: () => import('./layouts/formlayout.vue'),
        children: [
            {
                path: '',
                name: 'siparisview',
                component: () => import('./views/Forms/siparis.vue'),
            },
            {
                path: ':id',
                name: 'siparisform',
                component: () => import('./views/Forms/siparis.vue'),
            }]
    },
    {
        path: '/Faturalar',
        props: {
            form: 'fatura'
        },
        component: () => import('./layouts/formlayout.vue'),
        children: [
            {
                path: '',
                name: 'faturaview',
                component: () => import('./views/Forms/fatura.vue'),
            },
            {
                path: ':id',
                name: 'faturaform',
                component: () => import('./views/Forms/fatura.vue'),
            },
            {
                path: ':view',
                name: 'faturaviewIn',
                component: () => import('./views/Forms/fatura.vue'),
            }]
    },
    {
        path: '/gelenteklif',
        props: {
            form: 'gelenteklif'
        },
        component: () => import('./layouts/formlayout.vue'),
        children: [
            {
                path: '',
                name: 'gelenteklifview',
                component: () => import('./views/Forms/gelenteklif.vue'),
            },
            {
                path: ':id',
                name: 'gelenteklifform',
                component: () => import('./views/Forms/gelenteklif.vue'),
            }]
    },


    {
        path: '/test',
        props: {
            form: 'Test'
        },
        component: () => import('./views/test.vue'),
    },
    {
        path: '/test/about',
        props: {
            form: 'About'
        },
        component: () => import('./layouts/pagelayout.vue'),
        children: [
            {
                path: '',
                component: () => import('./views/About.vue'),
            }]
    },
    {
        path: '*',
        props: {
            form: 'home'
        },
        component: () => import('./layouts/pagelayout.vue'),
        children: [
            {
                path: '',
                component: () => import('./views/Home.vue'),
            }]
    },
];

const router = new Router({
    routes: _routes
})

router.beforeEach((to, from, next) =>
{
    var lang = Utilities.GetCurrentLanguage();
    loadLanguageAsync(lang);

    const _props = router.match(to.path).matched.at(0).props.default;

    if (_props && _props.allowUnAuthorized)
    {
        next();
        return;
    }

    if (store.state.userInfo.Login == "0")
    {
        next({ name: 'account.login' });
        return;
    }

    next();
});

export default router;