var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "history" },
    [
      _vm.editableForward && _vm.canSelectApprover
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("autocomplete", {
                    staticClass: "my-1",
                    class: { fielderror: _vm.error },
                    attrs: {
                      outlined: "",
                      invalue: _vm.oonayad,
                      isLoading: false,
                      itemtext: _vm.autoCompleteProperties.searchText,
                      itemvalue: _vm.autoCompleteProperties.searchText,
                      minLength: _vm.autoCompleteProperties.minLength,
                      error: _vm.error,
                      label: _vm.$t("FL_ForwardName"),
                      params: _vm.autoCompleteProperties,
                      handleChangeAndSearchInComponent: true,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.oonayadChanged($event)
                      },
                    },
                    model: {
                      value: _vm.oonayad,
                      callback: function ($$v) {
                        _vm.oonayad = $$v
                      },
                      expression: "oonayad",
                    },
                  }),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "6" } }, [
                _c("div", { staticClass: "caption font-weight-thin" }, [
                  _vm._v(" " + _vm._s(_vm.$t("FL_ForwardTitle")) + " "),
                ]),
                _c(
                  "div",
                  {
                    class: {
                      "body-2": _vm.oonayTitle == "",
                      "body-1": _vm.oonayTitle != "",
                      "font-weight-thin": _vm.oonayTitle == "",
                      unspecified: _vm.oonayTitle == "",
                      "font-italic": _vm.oonayTitle == "",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("FormatColumn")(
                            _vm.oonayTitle,
                            _vm.column,
                            _vm.$t("ST_Undefined")
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.editable
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-textarea", {
                    class: { fielderror: _vm.error },
                    attrs: {
                      outlined: "",
                      "hide-details": "",
                      error: _vm.error,
                      value: _vm.value,
                      label: _vm.$t("FL_Note"),
                      placeholder: _vm.$t("FL_Note"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.NoteChanged($event)
                      },
                    },
                    model: {
                      value: _vm.value,
                      callback: function ($$v) {
                        _vm.value = $$v
                      },
                      expression: "value",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.oonaynots && _vm.oonaynots.length > 0
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-alert",
                    {
                      attrs: {
                        border: "left",
                        color: "secondary darken-3",
                        text: "",
                        dense: "",
                      },
                    },
                    [
                      _c("div", { staticClass: "text-h8" }, [
                        _vm._v(" " + _vm._s(_vm.$t("FL_Notes")) + " "),
                      ]),
                      _vm._l(_vm.oonaynots, function (note) {
                        return _c("span", {
                          domProps: { innerHTML: _vm._s(note.history) },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-alert",
                    {
                      attrs: {
                        border: "left",
                        color: "secondary darken-3",
                        text: "",
                        dense: "",
                      },
                    },
                    [
                      _c("div", { staticClass: "text-h8" }, [
                        _vm._v(" " + _vm._s(_vm.$t("FL_Notes")) + " "),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.$t("IM_NotesEmpty")))]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }