var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.Hide
    ? _c(
        "div",
        { class: _vm.GetAlignment(_vm.column) },
        [
          !_vm.Editable
            ? _c(
                "span",
                { class: _vm.GetAlignment(_vm.column) },
                [
                  _vm.column.type == "file"
                    ? _c("upload", {
                        staticClass: "my-1",
                        class: { fielderror: _vm.Error },
                        attrs: {
                          editable: _vm.Editable,
                          params: _vm.column,
                          invalue: _vm.value,
                          label: _vm.$t(_vm.column.label),
                          readonly: _vm.Readonly,
                          approved: true,
                          id: _vm.tableName + "_" + _vm.col + "_" + _vm.row,
                          isInGrid: true,
                          disabled: _vm.Disabled,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.UpdateValueFile($event, _vm.column)
                          },
                          changeAttribute: function ($event) {
                            return _vm.ChangeAttributeFile($event)
                          },
                        },
                        model: {
                          value: _vm.value,
                          callback: function ($$v) {
                            _vm.value = $$v
                          },
                          expression: "value",
                        },
                      })
                    : _vm.column.type != "button"
                    ? _c("span", { class: _vm.GetAlignment(_vm.column) }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("FormatColumn")(
                                _vm.value,
                                _vm.column,
                                _vm.$t("ST_Undefined")
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.column.type == "button"
            ? _c(
                "v-btn",
                {
                  attrs: {
                    depressed: "",
                    block: "",
                    color: "primary white--text",
                    value: _vm.$t(_vm.column.label),
                    error: _vm.Error,
                    readonly: _vm.Readonly,
                    disabled: _vm.Disabled,
                    rows: _vm.column.height,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.ButtonClicked(_vm.column)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t(_vm.column.label)))]
              )
            : _vm._e(),
          _vm.column.type == "text" && _vm.Editable
            ? _c("v-text-field", {
                attrs: {
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  placeholder: _vm.PlaceHolder,
                  error: _vm.Error,
                  readonly: _vm.Readonly,
                  disabled: _vm.Disabled,
                  id: _vm.tableName + "_" + _vm.col + "_" + _vm.row,
                  color: "accent",
                },
                on: {
                  keyup: function ($event) {
                    return _vm.RowUpDown($event)
                  },
                  change: function ($event) {
                    return _vm.UpdateValue($event, _vm.column)
                  },
                },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              })
            : _vm._e(),
          _vm.column.type == "textarea" && _vm.Editable
            ? _c("v-textarea", {
                attrs: {
                  placeholder: _vm.$t(_vm.column.label),
                  "auto-grow": "",
                  error: _vm.Error,
                  readonly: _vm.Readonly,
                  disabled: _vm.Disabled,
                  id: _vm.tableName + "_" + _vm.col + "_" + _vm.row,
                  rows: _vm.column.height,
                },
                on: {
                  change: function ($event) {
                    return _vm.UpdateValue($event, _vm.column)
                  },
                },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              })
            : _vm._e(),
          (_vm.column.type == "number" || _vm.column.type == "int") &&
          _vm.Editable
            ? _c("v-text-field", {
                attrs: {
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  type: "number",
                  placeholder: _vm.PlaceHolder,
                  error: _vm.Error,
                  readonly: _vm.Readonly,
                  disabled: _vm.Disabled,
                  id: _vm.tableName + "_" + _vm.col + "_" + _vm.row,
                  color: "accent",
                },
                on: {
                  keyup: function ($event) {
                    return _vm.RowUpDown($event)
                  },
                  change: function ($event) {
                    return _vm.UpdateValue($event, _vm.column)
                  },
                },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              })
            : _vm._e(),
          _vm.column.type == "checkbox" && _vm.Editable
            ? _c("v-checkbox", {
                staticClass: "shrink mr-2 mt-0",
                attrs: {
                  "hide-details": "",
                  placeholder: _vm.$t(_vm.column.label),
                  error: _vm.Error,
                  readonly: _vm.Readonly,
                  disabled: _vm.Disabled,
                  id: _vm.tableName + "_" + _vm.col + "_" + _vm.row,
                },
                on: {
                  change: function ($event) {
                    return _vm.UpdateCheckbox($event, _vm.column)
                  },
                },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              })
            : _vm._e(),
          _vm.column.type == "link"
            ? _c(
                "span",
                {
                  staticStyle: { color: "blue", cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.GetLink(_vm.column)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.value))]
              )
            : _vm._e(),
          _vm.column.type == "select" && _vm.Editable
            ? _c("v-select", {
                attrs: {
                  outlined: "",
                  dense: "",
                  "background-color": "white",
                  "hide-details": "",
                  placeholder: _vm.$t(_vm.column.label),
                  id: _vm.tableName + "_" + _vm.col + "_" + _vm.row,
                  invalue: _vm.value,
                  items: _vm.column.options,
                  error: _vm.Error,
                  readonly: _vm.Readonly,
                  disabled: _vm.Disabled,
                  multiple: _vm.column.Multiple,
                  color: "accent",
                },
                on: {
                  change: function ($event) {
                    return _vm.UpdateSelect($event, _vm.column)
                  },
                },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              })
            : _vm._e(),
          _vm.column.type == "file" && _vm.Editable
            ? _c("upload", {
                staticClass: "my-1",
                class: { fielderror: _vm.Error },
                attrs: {
                  editable: _vm.Editable,
                  params: _vm.column,
                  invalue: _vm.value,
                  label: _vm.$t(_vm.column.label),
                  readonly: _vm.Readonly,
                  error: _vm.Error,
                  approved: true,
                  id: _vm.tableName + "_" + _vm.col + "_" + _vm.row,
                  isInGrid: true,
                  disabled: _vm.Disabled,
                },
                on: {
                  change: function ($event) {
                    return _vm.UpdateValueFile($event, _vm.column)
                  },
                  changeAttribute: function ($event) {
                    return _vm.ChangeAttributeFile($event)
                  },
                },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              })
            : _vm._e(),
          _vm.column.type == "iconic"
            ? _c("v-icon", [_vm._v(_vm._s(_vm.value))])
            : _vm._e(),
          _vm.column.type == "date" && _vm.Editable
            ? _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-content-click": false,
                    "nudge-right": 40,
                    transition: "scale-transition",
                    "offset-y": "",
                    "min-width": "auto",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c("v-text-field", {
                              staticClass: "my-1",
                              class: {
                                fielderror: _vm.Error,
                                fieldDisabled: _vm.Disabled,
                              },
                              attrs: {
                                dense: "",
                                outlined: "",
                                "background-color": _vm.Disabled
                                  ? "grey lighten-3"
                                  : "white",
                                "hide-details": "",
                                "append-icon": "mdi-calendar-month",
                                value: _vm._f("FormatDateTR")(_vm.value),
                                disabled: _vm.Disabled,
                                readonly: "",
                                clearable: "",
                                id:
                                  _vm.tableName + "_" + _vm.col + "_" + _vm.row,
                                placeholder: _vm.$t(_vm.column.label),
                                color: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.dateopen = !_vm.dateopen
                                },
                                "click:clear": function ($event) {
                                  return _vm.ClearDate($event, _vm.column)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1932024953
                  ),
                  model: {
                    value: _vm.dateopen,
                    callback: function ($$v) {
                      _vm.dateopen = $$v
                    },
                    expression: "dateopen",
                  },
                },
                [
                  _vm.dateopen
                    ? _c("v-date-picker", {
                        attrs: {
                          disabled: _vm.Disabled,
                          color: "primary lighten-2",
                          "header-color": "primary",
                          locale: "tr",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.UpdateValue($event, _vm.column)
                          },
                          input: function ($event) {
                            _vm.menu2 = false
                          },
                        },
                        model: {
                          value: _vm.value,
                          callback: function ($$v) {
                            _vm.value = $$v
                          },
                          expression: "value",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.column.type == "auto" && _vm.Editable
            ? _c("autocomplete", {
                attrs: {
                  invalue: _vm.value,
                  itemvalue: _vm.column.searchText,
                  minLength: _vm.column.minLength,
                  dense: "",
                  items: _vm.GetAttrItems(_vm.attributes),
                  loading: _vm.isLoading,
                  "search-input": _vm.search,
                  "item-text": "Description",
                  "item-value": "API",
                  "return-object": "",
                  id: _vm.tableName + "_" + _vm.col + "_" + _vm.row,
                  isInGrid: true,
                  error: _vm.Error,
                  readonly: _vm.Readonly,
                  disabled: _vm.Disabled,
                },
                on: {
                  "update:searchInput": function ($event) {
                    _vm.search = $event
                  },
                  "update:search-input": function ($event) {
                    _vm.search = $event
                  },
                  search: function ($event) {
                    return _vm.SearchInputChangedDebounced(
                      $event,
                      _vm.column.name
                    )
                  },
                  change: function ($event) {
                    return _vm.UpdateValue($event, _vm.column)
                  },
                  changeAttribute: function ($event) {
                    return _vm.ChangeAttribute($event)
                  },
                },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }