var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "390" },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "mx-auto pt-7 pb-2" },
            [
              _c("alertIcon", { attrs: { icon: _vm.vicon } }),
              _c("v-card-title", { staticClass: "mb-0" }, [
                _c("p", { staticClass: "mx-auto headline pb-0 mb-0" }, [
                  _vm._v(_vm._s(_vm.infoBody)),
                ]),
              ]),
              _c("v-card-text", {
                staticClass: "text-center",
                domProps: { innerHTML: _vm._s(_vm.messageBody) },
              }),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm.messageType.indexOf("C") >= 0
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "white--text font-weight-bolder px-5",
                          attrs: { text: "", color: _vm.color },
                          on: {
                            click: function ($event) {
                              return _vm.CloseDialogWithResult("approve")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("AL_Yes")) + " ")]
                      )
                    : _vm._e(),
                  _vm.messageType.indexOf("C") >= 0
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-bolder px-5",
                          staticStyle: { color: "#a4a2a2" },
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.CloseDialogWithResult("reject")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("AL_Reject")) + " ")]
                      )
                    : _c(
                        "v-btn",
                        {
                          staticClass: "white--text font-weight-bolder px-5",
                          attrs: { text: "", color: _vm.color },
                          on: {
                            click: function ($event) {
                              return _vm.CloseDialog()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("AL_Okey")) + " ")]
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          staticClass: "primary--text text--lighten-5",
          attrs: {
            timeout: 3000,
            "multi-line": true,
            color: _vm.color,
            top: "",
            right: "",
          },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function ({ attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "primary--text ligthen-4", icon: "" },
                        on: {
                          click: function ($event) {
                            _vm.notification = false
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.notification,
            callback: function ($$v) {
              _vm.notification = $$v
            },
            expression: "notification",
          },
        },
        [
          _vm.messageType == "E" && _vm.errors && _vm.errors.length > 0
            ? _c(
                "v-list",
                {
                  staticClass: "ma-0 pa-0 overflow-y-auto",
                  staticStyle: { "max-height": "200px" },
                  attrs: {
                    dense: "",
                    id: "idlistofnotif",
                    color: "transparent",
                  },
                },
                _vm._l(_vm.errors, function (err) {
                  return _c(
                    "v-list-item",
                    { key: err.index },
                    [
                      _c("v-list-item-content", [
                        _c("span", {
                          staticClass: "notification",
                          domProps: { innerHTML: _vm._s(err) },
                        }),
                      ]),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm.messageBody
            ? _c(
                "v-list",
                {
                  staticClass: "ma-0 pa-0",
                  attrs: { dense: "", color: "transparent" },
                },
                [
                  _c(
                    "v-list-item",
                    [
                      _c("v-list-item-content", [
                        _vm._v(" " + _vm._s(_vm.messageBody) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }