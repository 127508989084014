var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.Hide
    ? _c("div", [
        _vm.column.type != "grid"
          ? _c("div", [
              !_vm.Editable
                ? _c(
                    "div",
                    [
                      _vm.column.type != "fileHistory" &&
                      _vm.column.type != "alert"
                        ? _c(
                            "div",
                            {
                              staticClass: "caption font-weight-thin",
                              class: { fieldHidden: _vm.Hidden },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t(_vm.column.label)) + " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.column.type == "alert"
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "clearMarginBottom",
                              class: { fieldHidden: _vm.Hidden },
                              attrs: { type: _vm.column.messageType },
                            },
                            [
                              _c("span", {
                                staticClass: "notification",
                                domProps: {
                                  innerHTML: _vm._s(_vm.$t(_vm.column.message)),
                                },
                              }),
                            ]
                          )
                        : _vm.column.type == "history"
                        ? _c(
                            "div",
                            { class: { fieldHidden: _vm.Hidden } },
                            [
                              _c("history", {
                                attrs: {
                                  editable: _vm.Editable,
                                  label: _vm.$t(_vm.column.label),
                                  column: _vm.column,
                                  invalue: _vm.value,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.column.type == "fileHistory"
                        ? _c(
                            "div",
                            { class: { fieldHidden: _vm.Hidden } },
                            [_c("fileHistory")],
                            1
                          )
                        : _vm.column.type == "html"
                        ? _c("div", { class: { fieldHidden: _vm.Hidden } }, [
                            _c("p", {
                              staticClass: "card-text",
                              domProps: { innerHTML: _vm._s(_vm.value) },
                            }),
                          ])
                        : _vm.column.type == "file"
                        ? _c("upload", {
                            staticClass: "my-1",
                            class: {
                              fielderror: _vm.Error,
                              fieldHidden: _vm.Hidden,
                            },
                            attrs: {
                              editable: _vm.Editable,
                              params: _vm.column,
                              invalue: _vm.value,
                              label: "Label",
                              readonly: _vm.Readonly,
                              approved: _vm.Approved,
                              disabled: _vm.Disabled,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.UpdateValueFile($event, _vm.column)
                              },
                              changeAttribute: function ($event) {
                                return _vm.ChangeAttribute($event)
                              },
                            },
                          })
                        : _vm.column.type == "url"
                        ? _c(
                            "v-chip",
                            {
                              class: { fieldHidden: _vm.Hidden },
                              staticStyle: { border: "1px solid #bdbdbd" },
                              attrs: {
                                label: "",
                                solo: "",
                                flat: "",
                                pill: "",
                                "hide-details": "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.OpenUrl()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t(_vm.column.label)) +
                                  " | " +
                                  _vm._s(_vm.value)
                              ),
                            ]
                          )
                        : _vm.column.type == "select"
                        ? _c(
                            "div",
                            {
                              staticClass: "body-1",
                              class: { fieldHidden: _vm.Hidden },
                            },
                            [_vm._v(" " + _vm._s(_vm.GetSelectText) + " ")]
                          )
                        : _vm.column.type != "image" &&
                          _vm.column.type != "link" &&
                          _vm.column.type != "checkbox"
                        ? _c("div", { class: { fieldHidden: _vm.Hidden } }, [
                            _vm.column.type == "datetime"
                              ? _c(
                                  "div",
                                  {
                                    class: {
                                      "body-2": _vm.value == "",
                                      "body-1": _vm.value != "",
                                      "font-weight-thin": _vm.value == "",
                                      unspecified: _vm.value == "",
                                      "font-italic": _vm.value == "",
                                      fieldHidden: _vm.Hidden,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("FormatDateTimeTR")(
                                            _vm.value,
                                            _vm.column,
                                            _vm.$t("ST_Undefined"),
                                            _vm.$t("ST_Indefinite")
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm.column.type == "date"
                              ? _c(
                                  "div",
                                  {
                                    class: {
                                      "body-2": _vm.value == "",
                                      "body-1": _vm.value != "",
                                      "font-weight-thin": _vm.value == "",
                                      unspecified: _vm.value == "",
                                      "font-italic": _vm.value == "",
                                      fieldHidden: _vm.Hidden,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("FormatDateTR")(
                                            _vm.value,
                                            _vm.$t("ST_Undefined"),
                                            _vm.$t("ST_Indefinite")
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    class: {
                                      "body-2": _vm.value == "",
                                      "body-1": _vm.value != "",
                                      "font-weight-thin": _vm.value == "",
                                      unspecified: _vm.value == "",
                                      "font-italic": _vm.value == "",
                                      fieldHidden: _vm.Hidden,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("FormatColumn")(
                                            _vm.value,
                                            _vm.column,
                                            this.GetLocale,
                                            _vm.$t("ST_Undefined"),
                                            _vm.$t("OP_PleaseSelect")
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                          ])
                        : _c(
                            "v-chip",
                            {
                              class: { fieldHidden: _vm.Hidden },
                              staticStyle: { border: "1px solid #bdbdbd" },
                              attrs: {
                                label: "",
                                solo: "",
                                flat: "",
                                pill: "",
                                "hide-details": "",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t(_vm.column.label)) +
                                  " | " +
                                  _vm._s(_vm.value)
                              ),
                            ]
                          ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _vm.column.type == "confirmbox"
                        ? _c(
                            "v-row",
                            { staticClass: "d-flex align-center" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.PrepareDocumentDialog(
                                        _vm.column.document
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-file-document-outline"),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-checkbox", {
                                class: {
                                  fielderror: _vm.Error,
                                  fieldHidden: _vm.Hidden,
                                },
                                attrs: {
                                  color: "primary",
                                  disabled: _vm.Disabled,
                                  error: _vm.Error,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.UpdateCheckbox(
                                      $event,
                                      _vm.column
                                    )
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "body-2",
                                              staticStyle: {
                                                "padding-top": "10px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    _vm.column.document +
                                                      "Description"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2980946420
                                ),
                                model: {
                                  value: _vm.value,
                                  callback: function ($$v) {
                                    _vm.value = $$v
                                  },
                                  expression: "value",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.column.type == "text" || _vm.column.type == "url"
                        ? _c("v-text-field", {
                            staticClass: "my-1",
                            class: {
                              fielderror: _vm.Error,
                              fieldDisabled: _vm.Disabled,
                              fieldHidden: _vm.Hidden,
                            },
                            attrs: {
                              "hide-details": "",
                              outlined: "",
                              placeholder: _vm.PlaceHolder,
                              label: _vm.Label,
                              error: _vm.Error,
                              readonly: _vm.Readonly,
                              disabled: _vm.Disabled,
                              "append-icon":
                                _vm.Approved == false ? "mdi-timer-sand" : "",
                              color: "primary",
                              "background-color": _vm.Disabled
                                ? "grey lighten-3"
                                : "white",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.UpdateValue($event, _vm.column)
                              },
                              "click:append": function ($event) {
                                return _vm.click()
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm.column.appendbutton && !_vm.Disabled
                                  ? {
                                      key: "append-outer",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-btn",
                                            {
                                              staticStyle: {
                                                "margin-top": "-6px !important",
                                              },
                                              attrs: { color: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.FieldButtonClicked(
                                                    _vm.column.appendbutton
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm.column.appendbuttonpreicon
                                                ? _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.column
                                                          .appendbuttonpreicon
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.column.appendbutton
                                                  ) +
                                                  " "
                                              ),
                                              _vm.column.appendbuttonappendicon
                                                ? _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.column
                                                          .appendbuttonappendicon
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    }
                                  : null,
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.value,
                              callback: function ($$v) {
                                _vm.value = $$v
                              },
                              expression: "value",
                            },
                          })
                        : _vm._e(),
                      _vm.column.type == "number" || _vm.column.type == "int"
                        ? _c("v-text-field", {
                            staticClass: "my-1",
                            class: {
                              fielderror: _vm.Error,
                              fieldDisabled: _vm.Disabled,
                              fieldHidden: _vm.Hidden,
                            },
                            attrs: {
                              outlined: "",
                              "background-color": _vm.Disabled
                                ? "grey lighten-3"
                                : "white",
                              "hide-details": "",
                              placeholder: _vm.$t(_vm.column.label),
                              label: _vm.Label,
                              error: _vm.Error,
                              readonly: _vm.Readonly,
                              disabled: _vm.Disabled,
                              "append-icon":
                                _vm.Approved == false ? "mdi-timer-sand" : "",
                              color: "primary",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.UpdateValue($event, _vm.column)
                              },
                              keydown: function ($event) {
                                return _vm.queryForKeywords($event)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm.column.appendbutton && !_vm.Disabled
                                  ? {
                                      key: "append-outer",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-btn",
                                            {
                                              staticStyle: {
                                                "margin-top": "-6px !important",
                                              },
                                              attrs: { color: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.FieldButtonClicked(
                                                    _vm.column.appendbutton
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm.column.appendbuttonpreicon
                                                ? _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.column
                                                          .appendbuttonpreicon
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.column.appendbutton
                                                  ) +
                                                  " "
                                              ),
                                              _vm.column.appendbuttonappendicon
                                                ? _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.column
                                                          .appendbuttonappendicon
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    }
                                  : null,
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.value,
                              callback: function ($$v) {
                                _vm.value = $$v
                              },
                              expression: "value",
                            },
                          })
                        : _vm._e(),
                      _vm.column.type == "history"
                        ? _c(
                            "div",
                            [
                              _c("history", {
                                class: {
                                  fielderror: _vm.Error,
                                  fieldDisabled: _vm.Disabled,
                                  fieldHidden: _vm.Hidden,
                                },
                                attrs: {
                                  editable: _vm.Editable,
                                  label: _vm.Label,
                                  column: _vm.column,
                                  error: _vm.Error,
                                  invalue: _vm.value,
                                  isPortal: _vm.column.isPortal,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.UpdateNote($event)
                                  },
                                },
                                model: {
                                  value: _vm.value,
                                  callback: function ($$v) {
                                    _vm.value = $$v
                                  },
                                  expression: "value",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.column.type == "textarea"
                        ? _c("v-textarea", {
                            staticClass: "my-1",
                            class: {
                              fielderror: _vm.Error,
                              fieldDisabled: _vm.Disabled,
                              fieldHidden: _vm.Hidden,
                            },
                            attrs: {
                              outlined: "",
                              "background-color": _vm.Disabled
                                ? "grey lighten-3"
                                : "white",
                              "hide-details": "",
                              placeholder: _vm.$t(_vm.column.label),
                              label: _vm.Label,
                              error: _vm.Error,
                              disabled: _vm.Disabled,
                              readonly: _vm.Readonly,
                              "append-icon":
                                _vm.Approved == false ? "mdi-timer-sand" : "",
                              color: "primary",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.UpdateValue($event, _vm.column)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm.column.appendbutton && !_vm.Disabled
                                  ? {
                                      key: "append-outer",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-btn",
                                            {
                                              staticStyle: {
                                                "margin-top": "-6px !important",
                                              },
                                              attrs: { color: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.FieldButtonClicked(
                                                    _vm.column.appendbutton
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm.column.appendbuttonpreicon
                                                ? _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.column
                                                          .appendbuttonpreicon
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.column.appendbutton
                                                  ) +
                                                  " "
                                              ),
                                              _vm.column.appendbuttonappendicon
                                                ? _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.column
                                                          .appendbuttonappendicon
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    }
                                  : null,
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.value,
                              callback: function ($$v) {
                                _vm.value = $$v
                              },
                              expression: "value",
                            },
                          })
                        : _vm._e(),
                      _vm.column.type == "checkbox" && _vm.Editable
                        ? _c("v-checkbox", {
                            staticClass: "align-self-stretch",
                            class: {
                              fielderror: _vm.Error,
                              fieldDisabled: _vm.Disabled,
                              fieldHidden: _vm.Hidden,
                            },
                            attrs: {
                              solo: "",
                              flat: "",
                              "background-color": _vm.Disabled
                                ? "grey lighten-3"
                                : "white",
                              "hide-details": "",
                              disabled: _vm.Disabled,
                              error: _vm.Error,
                              color: "primary",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.UpdateCheckbox($event, _vm.column)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "body-2",
                                          staticStyle: {
                                            "padding-top": "10px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t(_vm.column.label))
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                _vm.column.appendbutton && !_vm.Disabled
                                  ? {
                                      key: "append-outer",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-btn",
                                            {
                                              staticStyle: {
                                                "margin-top": "-6px !important",
                                              },
                                              attrs: { color: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.FieldButtonClicked(
                                                    _vm.column.appendbutton
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm.column.appendbuttonpreicon
                                                ? _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.column
                                                          .appendbuttonpreicon
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.column.appendbutton
                                                  ) +
                                                  " "
                                              ),
                                              _vm.column.appendbuttonappendicon
                                                ? _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.column
                                                          .appendbuttonappendicon
                                                      )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    }
                                  : null,
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.value,
                              callback: function ($$v) {
                                _vm.value = $$v
                              },
                              expression: "value",
                            },
                          })
                        : _vm._e(),
                      _vm.column.type == "checkbox" && !_vm.Editable
                        ? _c("v-checkbox", {
                            staticClass: "align-self-stretch",
                            class: {
                              fielderror: _vm.Error,
                              fieldDisabled: _vm.Disabled,
                              fieldHidden: _vm.Hidden,
                            },
                            staticStyle: { border: "1px solid #bdbdbd" },
                            attrs: {
                              solo: "",
                              flat: "",
                              "background-color": _vm.Disabled
                                ? "grey lighten-3"
                                : "white",
                              "hide-details": "",
                              disabled: "",
                              error: _vm.Error,
                              color: "primary",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.UpdateCheckbox($event, _vm.column)
                              },
                            },
                            model: {
                              value: _vm.value,
                              callback: function ($$v) {
                                _vm.value = $$v
                              },
                              expression: "value",
                            },
                          })
                        : _vm._e(),
                      _vm.column.type == "select"
                        ? _c("v-select", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.Editable,
                                expression: "Editable",
                              },
                            ],
                            staticClass: "my-1",
                            class: {
                              fielderror: _vm.Error,
                              fieldDisabled: _vm.Disabled,
                              fieldHidden: _vm.Hidden,
                            },
                            attrs: {
                              outlined: "",
                              "menu-props": "auto",
                              "hide-details": "",
                              multiple: _vm.column.multiple
                                ? _vm.column.multiple
                                : false,
                              "background-color": _vm.Disabled
                                ? "grey lighten-3"
                                : "white",
                              readonly: _vm.Readonly,
                              disabled: _vm.Disabled,
                              error: _vm.Error,
                              "append-icon":
                                _vm.Approved == false
                                  ? "mdi-timer-sand"
                                  : "mdi-menu-down",
                              items: _vm.GetSelectOptions,
                              "item-text": "text",
                              "item-value": "value",
                              placeholder: _vm.$t("OP_PleaseSelect"),
                              label: _vm.Label,
                              color: "primary",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.UpdateSelect($event, _vm.column)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "append-outer",
                                  fn: function () {
                                    return undefined
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1405559635
                            ),
                            model: {
                              value: _vm.value,
                              callback: function ($$v) {
                                _vm.value = $$v
                              },
                              expression: "value",
                            },
                          })
                        : _vm._e(),
                      _vm.column.type == "autocomplete" && _vm.Editable
                        ? _c("v-autocomplete", {
                            staticClass: "my-1",
                            class: {
                              fielderror: _vm.Error,
                              fieldDisabled: _vm.Disabled,
                              fieldHidden: _vm.Hidden,
                            },
                            attrs: {
                              chips: _vm.column.multiple
                                ? _vm.column.multiple
                                : false,
                              outlined: "",
                              "hide-details": "",
                              "deletable-chips": _vm.column.multiple
                                ? _vm.column.multiple
                                : false,
                              multiple: _vm.column.multiple
                                ? _vm.column.multiple
                                : false,
                              readonly: _vm.Readonly,
                              disabled: _vm.Disabled,
                              items: _vm.column.items,
                              "item-text": _vm.column.itemText,
                              "item-value": _vm.column.valueField,
                              invalue: _vm.value,
                              error: _vm.Error,
                              filter: _vm.column.filter,
                              placeholder: _vm.$t("OP_PleaseSelect"),
                              label: _vm.Label,
                              "search-input": _vm.column.searchInput,
                              "append-icon":
                                _vm.Approved == false ? "mdi-timer-sand" : "",
                            },
                            on: {
                              focus: function ($event) {
                                return _vm.changeSearchInputToTriggerFilter()
                              },
                              click: function ($event) {
                                return _vm.changeSearchInputToTriggerFilter()
                              },
                              "click:clear": function ($event) {
                                return _vm.changeSearchInputToTriggerFilter()
                              },
                              change: function ($event) {
                                return _vm.UpdateAutoComplete(
                                  $event,
                                  _vm.column
                                )
                              },
                              search: function ($event) {
                                return _vm.SearchInputChangedDebounced($event)
                              },
                            },
                            model: {
                              value: _vm.value,
                              callback: function ($$v) {
                                _vm.value = $$v
                              },
                              expression: "value",
                            },
                          })
                        : _vm._e(),
                      _vm.column.type == "checkboxgroup" && _vm.Editable
                        ? _c("checkboxgroup", {
                            attrs: {
                              value: _vm.value,
                              params: _vm.column,
                              readonly: _vm.Readonly,
                              disabled: _vm.Disabled,
                              error: _vm.Error,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.UpdateValue($event, _vm.column)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.column.type == "money" && _vm.Editable
                        ? _c("money", {
                            staticClass: "my-1",
                            class: {
                              fielderror: _vm.Error,
                              fieldDisabled: _vm.Disabled,
                              fieldHidden: _vm.Hidden,
                            },
                            attrs: {
                              params: _vm.column,
                              invalue: _vm.value,
                              doviz: _vm.currency,
                              label: _vm.Label,
                              readonly: _vm.Readonly,
                              disabled: _vm.Disabled,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.UpdateValueMoney($event, _vm.column)
                              },
                            },
                            model: {
                              value: _vm.value,
                              callback: function ($$v) {
                                _vm.value = $$v
                              },
                              expression: "value",
                            },
                          })
                        : _vm._e(),
                      _vm.column.type == "file"
                        ? _c("upload", {
                            staticClass: "my-1",
                            class: {
                              fielderror: _vm.Error,
                              fieldHidden: _vm.Hidden,
                            },
                            attrs: {
                              editable: _vm.Editable,
                              params: _vm.column,
                              invalue: _vm.value,
                              error: _vm.Error,
                              label: _vm.Label,
                              readonly: _vm.Readonly,
                              approved: _vm.Approved,
                              disabled: _vm.Disabled,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.UpdateValueFile($event, _vm.column)
                              },
                              changeAttribute: function ($event) {
                                return _vm.ChangeAttribute($event)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.column.type == "image"
                        ? _c("imageupload", {
                            class: {
                              fielderror: _vm.Error,
                              fieldDisabled: _vm.Disabled,
                              fieldHidden: _vm.Hidden,
                            },
                            attrs: {
                              editable: _vm.Editable,
                              params: _vm.column,
                              invalue: _vm.value,
                              label: _vm.Label,
                              readonly: _vm.Readonly,
                              disabled: _vm.Disabled,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.UpdateValueFile($event, _vm.column)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.column.type == "date" && _vm.Editable
                        ? _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-right": 40,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "auto",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "my-1",
                                                class: {
                                                  fielderror: _vm.Error,
                                                  fieldDisabled: _vm.Disabled,
                                                  fieldHidden: _vm.Hidden,
                                                },
                                                attrs: {
                                                  outlined: "",
                                                  "background-color":
                                                    _vm.Disabled
                                                      ? "grey lighten-3"
                                                      : "white",
                                                  "hide-details": "",
                                                  "append-icon":
                                                    _vm.Approved == false
                                                      ? "mdi-timer-sand"
                                                      : "mdi-calendar-month",
                                                  value: _vm._f("FormatDateTR")(
                                                    _vm.value
                                                  ),
                                                  error: _vm.Error,
                                                  disabled: _vm.Disabled,
                                                  readonly: "",
                                                  clearable: "",
                                                  label: _vm.Label,
                                                  color: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.dateopen = !_vm.dateopen
                                                  },
                                                  "click:clear": function (
                                                    $event
                                                  ) {
                                                    return _vm.ClearDate(
                                                      $event,
                                                      _vm.column
                                                    )
                                                  },
                                                },
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1162752876
                              ),
                              model: {
                                value: _vm.dateopen,
                                callback: function ($$v) {
                                  _vm.dateopen = $$v
                                },
                                expression: "dateopen",
                              },
                            },
                            [
                              _vm.dateopen
                                ? _c("v-date-picker", {
                                    attrs: {
                                      disabled: _vm.Disabled,
                                      color: "primary lighten-2",
                                      "header-color": "primary",
                                      min: _vm.GetMinMaxDate(
                                        _vm.column.mindate
                                      ),
                                      max: _vm.GetMinMaxDate(
                                        _vm.column.maxdate
                                      ),
                                      "allowed-dates": _vm.allowedDates,
                                      "first-day-of-week": 1,
                                      locale: "tr",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.UpdateValue(
                                          $event,
                                          _vm.column
                                        )
                                      },
                                      input: function ($event) {
                                        _vm.menu2 = false
                                      },
                                    },
                                    model: {
                                      value: _vm.value,
                                      callback: function ($$v) {
                                        _vm.value = $$v
                                      },
                                      expression: "value",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.column.type == "auto" && _vm.Editable
                        ? _c("autocomplete", {
                            staticClass: "my-1",
                            class: {
                              fielderror: _vm.Error,
                              fieldDisabled: _vm.Disabled,
                              fieldHidden: _vm.Hidden,
                            },
                            attrs: {
                              outlined: "",
                              invalue: _vm.value,
                              items: _vm.GetAttributes,
                              loading: _vm.isLoading,
                              itemtext: _vm.column.searchText,
                              itemvalue: _vm.column.searchText,
                              minLength: _vm.column.minLength,
                              error: _vm.Error,
                              readonly: _vm.Readonly,
                              disabled: _vm.Disabled,
                              approved: _vm.Approved,
                              label: _vm.Label,
                              params: _vm.column,
                            },
                            on: {
                              search: function ($event) {
                                return _vm.SearchInputChangedDebounced($event)
                              },
                              change: function ($event) {
                                return _vm.UpdateValueAutoComplete(
                                  $event,
                                  _vm.column
                                )
                              },
                            },
                            model: {
                              value: _vm.value,
                              callback: function ($$v) {
                                _vm.value = $$v
                              },
                              expression: "value",
                            },
                          })
                        : _vm._e(),
                      _vm.column.type == "link"
                        ? _c(
                            "v-btn",
                            {
                              staticStyle: { border: "1px solid #bdbdbd" },
                              attrs: {
                                block: "",
                                depressed: "",
                                solo: "",
                                flat: "",
                                color: "primary",
                                "append-icon": "mdi-cursor",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.GetLink()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t(_vm.column.label)) + " "
                              ),
                              _c("v-icon", { attrs: { right: "", dark: "" } }, [
                                _vm._v(" mdi-cursor-default-click "),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.column.type == "button"
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: _vm.column.buttoncolor },
                              on: {
                                click: function ($event) {
                                  return _vm.ButtonClick(_vm.column)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t(_vm.column.buttontext)) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
            ])
          : _c(
              "div",
              [
                _vm.column.type == "grid"
                  ? _c("grid", {
                      class: {
                        gridError: _vm.Error,
                        fieldDisabled: _vm.Disabled,
                        fieldHidden: _vm.Hidden,
                      },
                      staticStyle: { display: "inline-block" },
                      attrs: {
                        tableName: _vm.column.name,
                        columns: _vm.GetExdataColumns(_vm.column.name),
                        data: _vm.GetExdataItems(_vm.column.name),
                        header: _vm.Label,
                        showselect: _vm.column.showselect,
                        showExportToExcelButton:
                          _vm.column.showExportToExcelButton,
                        error: _vm.Error,
                        attributes: _vm.GetExtdataAttributes(_vm.column.name),
                        buttons: _vm.column.buttons,
                        addrow:
                          _vm.column.edit.indexOf(_vm.GetFormState) != -1 &&
                          _vm.ShowGridAddRow(_vm.column)
                            ? "AL_Add"
                            : "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.UpdateValueGrid($event, _vm.column.name)
                        },
                        selected: function ($event) {
                          return _vm.Selected($event)
                        },
                        SearchInputChanged: function ($event) {
                          return _vm.SearchInputChanged($event, _vm.column)
                        },
                        RowButtonClicked: function ($event) {
                          return _vm.RowButtonClicked($event, _vm.column)
                        },
                        AddRow: function ($event) {
                          return _vm.AddRow2ExtData($event, _vm.column.name)
                        },
                        HeaderButtonClicked: function ($event) {
                          return _vm.HeaderButtonClicked($event, _vm.column)
                        },
                      },
                    })
                  : _vm._e(),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.DumpGrid("grid column:", _vm.column, _vm.GetState)
                    )
                  ),
                ]),
              ],
              1
            ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }