var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data.length != 0
    ? _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c("grid", {
            attrs: {
              columns: _vm.GetColumns(),
              data: _vm.GetDataItems(),
              header: _vm.$t("TH_FileHistory"),
              attributes: _vm.GetFileHistoryAttributes,
              addrow: "",
              showExportToExcelButton: false,
              clickrow: 1 == 1,
            },
            on: {
              rowclicked: function ($event) {
                return _vm.RowClicked($event)
              },
              RowButtonClicked: function ($event) {
                return _vm.RowButtonClicked($event)
              },
            },
          }),
        ],
        1
      )
    : _c("div", [_vm._v(" " + _vm._s(_vm.$t("IM_NoRowsAtTable")) + " ")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }