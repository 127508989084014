var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-list",
        { attrs: { dark: "", nav: "" } },
        [
          _c(
            "v-list-item",
            {
              on: {
                click: function ($event) {
                  return _vm.BackToHomePage()
                },
              },
            },
            [
              _c(
                "v-list-item-action",
                [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", [
                    _vm._v(" " + _vm._s(_vm.$t("RL_Homepage")) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-list",
        { attrs: { dark: "", nav: "" } },
        [
          _c(
            "v-list-item-group",
            _vm._l(_vm.itemList, function (item) {
              return _c(
                "v-list-item",
                {
                  key: Math.floor(Math.random() * 1000000),
                  on: {
                    click: function ($event) {
                      return _vm.linkClicked(item)
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-action",
                    [_c("v-icon", [_vm._v("chevron_right")])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(" " + _vm._s(_vm.$t(item.linktitle)) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }